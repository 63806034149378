// AJAX



(function ($) {

  $(document).ready(function () {
    //  Validation du numéro de téléphone
    $("#formHotel").on("submit", function(e) {
      const tel = $("#tel").val();
      if (tel.length !== 10 || !/^\d+$/.test(tel)) {
        e.preventDefault();
        $("#errorp").text("Le numéro de téléphone doit contenir 10 chiffres");
        $("#tel").focus();
        return false;
      }
      $("#errorp").text("");
    });

    // Variables



    let inputSiret = $("#siret");



    let inputNaf = $("#naf");



    let searchTermZip;



    let companies = [];



    let company = [];



    let rsociale = $("#rsociale");



    const searchRs = $("#rsociale");



    const searchZip = $("#cp");



    searchZip.on("input", function () {

      if ($(this).val().length == 5) {

        searchTermZip = $(this).val();



        searchRs.attr("disabled", false);

      } else {

        searchRs.attr("disabled", true);

      }

    });



    searchRs.on("input", function () {

      let searchTermRs = $(this).val();



      let apiUrl =

        "https://recherche-entreprises.api.gouv.fr/search?q=" +

        searchTermRs +

        "&code_postal=" +

        searchTermZip +

        "&per_page=20";



      // Get data



      $.get(apiUrl, function (data) {

        companies = data.results.map((el) => el.nom_raison_sociale);



        // console.table(companies);



        // Autocomplete



        searchRs.autocomplete({

          minLength: 3,



          source: companies,

        });

      });

    });



    rsociale.on("blur", function (e) {

      // e.preventDefault(); // empêche l'envoi du formulaire



      // Dans le submit



      let rsValue = searchRs.val();



      let zipValue = searchZip.val();



      let apiUrl =

        "https://recherche-entreprises.api.gouv.fr/search?q=" +

        rsValue +

        "&code_postal=" +

        zipValue +

        "&per_page=20";



      // Get data



      $.get(apiUrl, function (data) {

        company["Siret"] = data.results.map((el) => el.siege.siret);



        company["NAF"] = data.results.map((el) => el.siege.activite_principale);



        // Insert data in fields



        // console.log(company);



        inputSiret.val(company["Siret"]);



        inputNaf.val(company["NAF"]);



        console.log(

          "Entreprise : " +

            rsValue +

            " | Code postal : " +

            zipValue +

            " | Siret : " +

            inputSiret.val() +

            " | NAF : " +

            inputNaf.val()

        );

      });

    });

  });

})(jQuery);



// URL TRACKING



const queryString = window.location.search;



const urlParams = new URLSearchParams(queryString);



const levier = urlParams.get("levier");

const support = urlParams.get("cmp_support");



var inputLevier = document.getElementById("src");

var inputSupport = document.getElementById("cmp_support");



levier ? (inputLevier.value = levier) : (inputLevier.value = "organique");

support ? (inputSupport.value = support) : (inputSupport.value = "organique");

